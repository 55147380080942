import React from "react";

const WorkShops = () => {
  return (
    <div className="bg-gray-100">
      <div className="mx-auto max-w-7xl py-10 px-4 sm:py-12 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-blue-500">
          Etapas de nuestra metodología
        </h2>
        <div className="mt-8 flow-root lg:mt-10">
          <div className="-mt-4 -ml-8 flex flex-wrap justify-between items-center lg:-ml-4">
            <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow lg:ml-4 lg:flex-grow-0 justify-center">
              <div className="flex flex-col items-center gap-2">
                <img
                  className="h-10 w-10 sm:h-14 sm:w-14"
                  src="https://user-images.githubusercontent.com/8493442/187457078-014cbb33-f97a-4d1f-86c8-b64506d50c7f.png"
                  alt=""
                />
                <h1 className="text-blue-500 font-medium text-lg">
                  Descubrimiento
                </h1>
              </div>
            </div>
            <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow lg:ml-4 lg:flex-grow-0 justify-center">
              <div className="flex flex-col items-center gap-2">
                <img
                  className="h-10 w-10 sm:h-14 sm:w-14"
                  src="https://user-images.githubusercontent.com/8493442/187457085-2dda91b1-52f5-4c2d-87de-2d6a6202d07b.png"
                  alt=""
                />
                <h1 className="text-blue-500 font-medium text-lg">Diseño</h1>
              </div>
            </div>
            <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow lg:ml-4 lg:flex-grow-0 justify-center">
              <div className="flex flex-col items-center gap-2">
                <img
                  className="h-10 w-10 sm:h-14 sm:w-14"
                  src="https://user-images.githubusercontent.com/8493442/187457072-875b53d0-f7bd-4f30-a575-c948ef26fe8a.png"
                  alt=""
                />
                <h1 className="text-blue-500 font-medium text-lg">
                  Definición
                </h1>
              </div>
            </div>
            <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow lg:ml-4 lg:flex-grow-0 justify-center">
              <div className="flex flex-col items-center gap-2">
                <img
                  className="h-10 w-10 sm:h-14 sm:w-14"
                  src="https://user-images.githubusercontent.com/8493442/187457075-441b9dec-01b0-46a6-908a-e704150e341c.png"
                  alt=""
                />
                <h1 className="text-blue-500 font-medium text-lg">
                  Desarrollo
                </h1>
              </div>
            </div>
            <div className="mt-4 ml-8 flex flex-shrink-0 flex-grow lg:ml-4 lg:flex-grow-0 justify-center">
              <div className="flex flex-col items-center gap-2">
                <img
                  className="h-10 w-10 sm:h-14 sm:w-14"
                  src="https://user-images.githubusercontent.com/8493442/187457080-024d0fed-a129-4940-a70a-8a9df89c4dff.png"
                  alt=""
                />
                <h1 className="text-blue-500 font-medium text-lg">
                  Despliegue
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkShops;
