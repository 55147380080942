import React from "react";

const TimelimitPost = () => {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-lg text-center text-blue-600 font-semibold">
              Rilow
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl sm:tracking-tight">
              El Valor del Límite de Tiempo
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Ningún proyecto escapa a esta situación. A todo proyecto siempre le
            podremos agregar, cambiar y quitar “cosas”. Es la naturaleza humana
            de siempre buscar más, de siempre soñar con más y mejor. Pero para
            un emprendimiento, las obras maestras generalmente no convienen.
          </p>
        </div>
        <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
          <p>Dice una frase popular:</p>
          <blockquote>
            <p>Un libro se termina de escribir cuando se publica.</p>
          </blockquote>
          <p>
            Lo que quiere decir es que hasta no publicar, estaremos tentados a
            hacer modificaciones, y que al publicar, por más que queramos hacer
            esos cambios, el libro ya salió, ya no es posible hacerlo.
          </p>
          <p>
            Lo que quiere decir es que hasta no publicar, estaremos tentados a
            hacer modificaciones, y que al publicar, por más que queramos hacer
            esos cambios, el libro ya salió, ya no es posible hacerlo.
          </p>
          <figure>
            <img
              className="w-full rounded-lg"
              src="https://images.pexels.com/photos/12829900/pexels-photo-12829900.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt=""
              width={1310}
              height={873}
            />
            <figcaption>FigCaption.</figcaption>
          </figure>
          <p>
            Si esta situación es verdad, entonces es correcto forzarnos a
            publicar, a entregar; a salir a la realidad.
          </p>
          <p>
            La realidad es diferente para cada proyecto. Para un libro la
            realidad son los primeros lectores. Para una App son los primeros
            usuarios. Para un servicio son los primeros clientes.
          </p>
          <h2>¿Cuándo saber el momento correcto para salir a la realidad?</h2>
          <p>
            La verdad es que nunca lo sabremos nosotros y nadie mejor que la
            propia realidad para decirnos. <br /> La clave para decidir el
            momento de salida está en el concepto de “suficiente”. Cuando un
            libro es suficientemente apto puede salir a recibir
            retroalimentación de familiares y amigos. Lo mismo con una App o un
            servicio. Cuando la primera versión del proyecto evoluciona y es
            suficiente para ese primer círculo de realidad, podemos pasar al
            siguiente nivel...
          </p>
          <p>
            Hay dos preguntas que conviene hacer para definir un límite de
            tiempo:
            <br />
            <strong>¿Qué considero suficiente para salir a la realidad?</strong>
            <br />y una vez teniendo esta lista:
            <br /> <strong>¿En cuánto tiempo calculo poder lograrlo?</strong>
            <br />
          </p>
          <p>
            De aquí pasamos a poner una fecha y marcarla en piedra. Conviene
            compartir esta fecha con personas cercanas para que nos ayuden a
            poner presión externa y obligarnos a publicar en el día acordado. El
            objetivo es que se vuelva vergonzoso fallar en publicar ese día.
          </p>
          <p>Dice el compositor de música clásica Igor Stravinsky:</p>
          <blockquote>
            <p>
              Mi libertad es tanto mayor y más significativa entre más estrecha
              es la limitación que pongo a mi campo de acción y entre más me
              rodeo de obstáculos. Todo lo que disminuye las restricciones
              disminuye la fortaleza. Entre más restricciones uno se impone, más
              se libera uno mismo de las cadenas que esclavizan el espíritu.
            </p>
          </blockquote>
          <p>
            Las restricciones y las limitantes nos obligan a superarnos, a
            demostrar nuestra capacidad creativa. No tengas miedo de poner una
            fecha límite, ni de salir a la realidad con algo lejano a ser
            perfecto. Es más valioso liberarse de la ilusión de perfección y
            tener el valor de compartir con el mundo tu creación.
          </p>
          <p>
            Cuéntanos si has tenido la experiencia de tardarte en sacar un
            proyecto, también si has salido con demasiada anticipación.
          </p>

          {/* <strong>Dignissim</strong>{" "}
        <a href="#">Mattis mauris semper</a> 
        Podemos usar ligas y strongs */}
          {/* <ul role="list">
        <li>Quis elit egestas venenatis mattis dignissim.</li>
        <li>
          Cras cras lobortis vitae vivamus ultricies facilisis tempus.
        </li>
        <li>Orci in sit morbi dignissim metus diam arcu pretium.</li>
      </ul> 
      Podemos usar listas */}
          {/* <h2>Subtitle</h2> 
      Podemos agregar subtitulos*/}
          {/* <blockquote>
        <p>
          Emprender exige darlo todo; ir en contra de lo que se cree
          posible, hacer inumerables sacrificios... y más difícil que todo:
          navegar en soledad por las crueles tormentas de tu propia mente.
        </p>
      </blockquote> 
      Podemos hacer Quotes*/}
        </div>
      </div>
    </div>
  );
};

export default TimelimitPost;
