import React from "react";
import { Details, Footer, Header } from "../components";

const product = {
  name: "Estrategia de comercialización",
  imageSrc:
    "https://st4.depositphotos.com/10048732/23723/i/450/depositphotos_237231274-stock-photo-web-designer-designer-design-user.jpg",
  version: { name: "1.0", date: "Agosto  1, 2022", datetime: "2022-08-01" },
  description:
    "Descubre cómo hacer crecer tu negocio y optimizar tu estrategia de marketing, que te llevará a ti y a tu equipo al éxito.",
  goals: [
    "Explorar y responder las preguntas necesarias para la creación de una correcta estrategia comercial.",
  ],
  requirements: ["Etapa de Definición"],
  participants: ["De 2 a 8 personas."],
  activities: [
    "¿A quiénes les sirve más el proyecto?",
    "¿Dónde los encontramos?",
    "¿Cómo nos comunicamos con ellos?",
    "¿Quiénes nos ayudarían a hacer ruido?",
    "¿Qué ideas de campañas se nos ocurren?",
    "¿Qué requisitos necesitamos para vender?",
    "¿Cuánto podrían pagar?",
  ],
  deliverables: ["Documento con las respuestas a las preguntas clave"],
};

const details = {
  content: `
  <h2>Información general</h2>
  
  <p>Aqui va toda la descripcion de los talleres, para que puedan entender de que trata y como los ayudaremos a lograr sus objetivos</p>

  <ul role="list">
  <li>Primer punto de los detalles</li>
  <li>Segundo punto de los detalles</li>
  </ul>
`,
};

const faqs = [
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Get",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
];

const MarketingPage = () => {
  return (
    <div className="bg-gray-50">
      <Header />
      <Details product={product} faqs={faqs} details={details} />
      <Footer />
    </div>
  );
};

export default MarketingPage;
