import React from "react";
import { CheckIcon } from "@heroicons/react/outline";

const pricing = {
  tiers: [
    {
      title: "MVP Diseño Premium",
      price: "1,600 USD",
      frequency: "/Pago Unico",
      description: "Tiempo de entrega: 4 semanas.",
      features: [
        "Etapa de Descubrimiento",
        "Etapa de Diseño.",
        "Ejemplos de los entregables.",
        "Guía nivel 2",
      ],
      cta: "Más información",
      mostPopular: false,
    },
    {
      title: "MVP Diseño Express",
      price: "2,160 USD",
      frequency: "/Pago Unico",
      description: "Tiempo de entrega: 2 semanas.",
      features: [
        "Etapa de Descubrimiento.",
        "Etapa de Diseño.",
        "Ejemplos de los entregables.",
        "Guía nivel 3.",
      ],
      cta: "Más información",
      mostPopular: false,
    },
    {
      title: "MVP Código Premium",
      price: "1,920 USD",
      frequency: "/Pago Unico",
      description: "Tiempo de entrega: 4 semanas.",
      features: [
        "Etapa de Definición.",
        "Etapa de Desarrollo.",
        "Ejemplos de los entregables.",
        "Guía nivel 2",
      ],
      cta: "Más información",
      mostPopular: false,
    },
    {
      title: "MVP Código Express",
      price: "2,590 USD",
      frequency: "/Pago Unico",
      description: "Tiempo de entrega: 2 semanas.",
      features: [
        "Etapa de Definición.",
        "Etapa de Desarrollo.",
        "Ejemplos de los entregables.",
        "Guía nivel 3.",
      ],
      cta: "Más información",
      mostPopular: false,
    },
  ],
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const Pricing = () => {
  return (
    <div className="max-w-7xl mx-auto py-16 px-4 bg-white sm:px-6 lg:px-8">
      <h2 className="text-3xl tracking-tight font-bold text-gray-900 sm:text-5xl sm:tracking-tight sm:leading-none lg:text-6xl lg:tracking-tight">
        Planes Rilow
      </h2>
      <p className="mt-6 mx-auto max-w-2xl text-xl text-gray-500">
        ¿Prefieres un camino ya probado?
      </p>

      {/* Tiers */}
      <div className="mt-24 space-y-12 md:space-y-6 md:grid md:grid-cols-2 md:gap-x-4 lg:space-y-0 lg:grid lg:grid-cols-4 lg:gap-x-8">
        {pricing.tiers.map((tier) => (
          <div
            key={tier.title}
            className="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col"
          >
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-gray-900">
                {tier.title}
              </h3>
              {tier.mostPopular ? (
                <p className="absolute top-0 py-1.5 px-4 bg-blue-500 rounded-full text-sm font-semibold text-white transform -translate-y-1/2">
                  Mas Popular
                </p>
              ) : null}
              <p className="mt-4 flex items-baseline text-gray-900">
                <span className="text-3xl tracking-tight font-bold text-right">
                  ${tier.price}
                </span>
                <span className="ml-1 text-xl font-semibold text-left">
                  {tier.frequency}
                </span>
              </p>
              <p className="mt-6 text-gray-500">{tier.description}</p>

              {/* Feature list */}
              <ul className="mt-6 space-y-6">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex">
                    <CheckIcon
                      className="flex-shrink-0 w-6 h-6 text-blue-500"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>

            <a
              href="https://wa.me/+525530116076?text=Hola%20estoy%20interesado%20en%20un%20taller%20de%20Rilow"
              className={classNames(
                tier.mostPopular
                  ? "bg-blue-500 text-white hover:bg-blue-600 "
                  : "bg-blue-50 text-blue-700 hover:bg-blue-100 ",
                "mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium "
              )}
            >
              {tier.cta}
            </a>
          </div>
        ))}
      </div>
      <div className="max-w-7xl px-4 bg-white sm:px-6 lg:px-8">
        <a href="/market">
          <p className="mt-6 mx-auto max-w-2xl text-xl text-blue-700">
            Ver todos los talleres
          </p>
        </a>
      </div>
    </div>
  );
};

export default Pricing;
