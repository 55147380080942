import React from "react";
import { Footer, Header, Product } from "../components";

const products = [
  {
    id: 1,
    name: "Valores y Metas",
    autor: "Impartido por Rilow",
    description:
      "Si buscas descubrir y comprender tus valores, visión y propósito en la vida, este taller es perfecto para ti.",
    category: "Descubrimiento",
    href: "/values",
    imageAlt: "Valores y Metas",
    imageSrc:
      "https://images.pexels.com/photos/3782144/pexels-photo-3782144.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: 2,
    name: "Persona Objetivo",
    autor: "Impartido por Rilow",
    description:
      "El taller nos ayudará a encontrar a nuestra persona objetivo y entender su tipo de personalidad. Podemos aprender sobre las diferentes cualidades que son importantes para nuestra cultura de producto, como fortalezas y debilidades, estilos comunes, intereses, etc.",
    category: "Descubrimiento",
    href: "/person",
    imageAlt: "Persona Objetivo",
    imageSrc:
      "https://images.pexels.com/photos/3782142/pexels-photo-3782142.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: 3,
    name: "Reto del proyecto",
    autor: "Impartido por Rilow",
    description:
      "Encontrar los desafíos de su proyecto es un paso clave en el proceso de desarrollo del proyecto. Este taller lo ayudará a hacer las preguntas correctas, comprender su propia perspectiva y desarrollar un plan práctico para superar esos desafíos.",
    category: "Descubrimiento",
    href: "/challenges",
    imageAlt: "Reto del proyecto",
    imageSrc:
      "https://images.pexels.com/photos/2433291/pexels-photo-2433291.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: 4,
    name: "Análisis de la competencia",
    autor: "Impartido por Rilow",
    description:
      "Para que el negocio tenga éxito, es importante saber qué competencia existe. Este taller lo guiará a través de cómo encontrar a sus competidores, cómo operan y cómo mantenerse competitivo.",
    category: "Descubrimiento",
    href: "/competitor",
    imageAlt: "Análisis de la competencia",
    imageSrc:
      "https://images.pexels.com/photos/7688336/pexels-photo-7688336.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: 5,
    name: "Secciones y Funciones",
    autor: "Impartido por Rilow",
    description:
      "Este taller puede ayudarnos a encontrar nuestras secciones y funciones de su producto. Nos permitirá comprender la estructura de su producto para crear excelentes productos con una mejor experiencia de usuario.",
    category: "Descubrimiento",
    href: "/functions",
    imageAlt: "Secciones y Funciones",
    imageSrc:
      "https://images.pexels.com/photos/3782229/pexels-photo-3782229.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: 6,
    name: "Métricas clave",
    autor: "Impartido por Rilow",
    description:
      "Podemos ayudarlo a encontrar sus métricas clave para comunicar el valor de su producto a sus clientes. Este taller lo ayudará a determinar qué métricas son las más importantes para su negocio y cómo medirlas de manera efectiva.",
    category: "Descubrimiento",
    href: "/metrics",
    imageAlt: "Métricas clave",
    imageSrc:
      "https://images.pexels.com/photos/7948054/pexels-photo-7948054.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: 7,
    name: "Modelo de negocio",
    autor: "Impartido por Rilow",
    description:
      "Empiece hoy. Trabaje con una empresa que pueda identificar su modelo de negocio y cómo crecer.",
    category: "Descubrimiento",
    href: "/model",
    imageAlt: "Modelo de negocio",
    imageSrc:
      "https://images.pexels.com/photos/3184430/pexels-photo-3184430.jpeg",
  },
  {
    id: 8,
    name: "Diseño versión 1",
    autor: "Impartido por Rilow",
    description:
      "Este taller lo ayudará a encontrar la estructura de su producto. Este es un flujo de trabajo paso a paso que lleva el viaje del usuario a través de su producto y le brindará un mapa único desde la visualización de conceptos básicos hasta esquemas presentables.",
    category: "Diseño",
    href: "/wireframes",
    imageAlt: "Diseño versión 1",
    imageSrc:
      "https://images.pexels.com/photos/196644/pexels-photo-196644.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: 8,
    name: "Diseño versión 2",
    autor: "Impartido por Rilow",
    description:
      "Llegaste al mapa de tu idea y ahora te preguntas cómo ponerlo todo junto. Este taller es para ti. En esta sesión práctica, lo guiaremos a través de cómo crear un prototipo completamente funcional que valide sus ideas.",
    category: "Diseño",
    href: "/prototype",
    imageAlt: "Diseño versión 2",
    imageSrc:
      "https://st4.depositphotos.com/10048732/23723/i/450/depositphotos_237231274-stock-photo-web-designer-designer-design-user.jpg",
  },
  {
    id: 9,
    name: "Estrategia de comercialización",
    autor: "Impartido por Rilow",
    description:
      "Descubre cómo hacer crecer tu negocio y optimizar tu estrategia de marketing, que te llevará a ti y a tu equipo al éxito.",
    category: "Despliegue",
    href: "/marketing",
    imageAlt: "Estrategia de comercialización",
    imageSrc:
      "https://st4.depositphotos.com/10048732/23723/i/450/depositphotos_237231274-stock-photo-web-designer-designer-design-user.jpg",
  },
  {
    id: 10,
    name: "Landing Page",
    autor: "Impartido por Rilow",
    description:
      "Las Landing Pages son la primera oportunidad para vender tu producto y la última oportunidad que tienes para causar una buena impresión en el visitante. Deben diseñarse para convertir a los visitantes en clientes centrándose en los beneficios, transmitiendo confianza y credibilidad, demostrando lo fácil que es usar su producto y, cuando sea posible, ofreciendo algún tipo de incentivo u oferta por tiempo limitado.",
    category: "Despliegue",
    href: "/landing",
    imageAlt: "Landing Page",
    imageSrc:
      "https://media.istockphoto.com/photos/website-creative-plan-development-with-drawn-sketch-in-the-design-picture-id1221435509?k=20&m=1221435509&s=612x612&w=0&h=JWs9wwlkwP6PYvDE4OuSYCSI9a3UYK8xwaeK-gtNiiE=",
  },
  {
    id: 11,
    name: "Materiales de ventas",
    autor: "Impartido por Rilow",
    description:
      "Aprenderá a desarrollar materiales de venta atractivos e informativos que reflejen su producto o servicio. Obtiene experiencia práctica con nuestro taller y todas las herramientas para crear su propio material de ventas de marca y aspecto profesional. ¡Este taller es una excelente manera de aprender de primera mano qué funciona y qué no!",
    category: "Despliegue",
    href: "/salesmaterials",
    imageAlt: "Materiales de ventas",
    imageSrc:
      "https://images.pexels.com/photos/6476808/pexels-photo-6476808.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
];

const MarketPage = () => {
  return (
    <div className="bg-gray-50">
      <Header />
      <Product products={products} />
      <Footer />
    </div>
  );
};

export default MarketPage;
