import React from "react";
import { Route, Routes } from "react-router-dom";
import "./assets/App.css";
import {
  HomePage,
  ApiDetailsPage,
  MarketPage,
  ValuesPage,
  PersonPage,
  FunctionsPage,
  EntrepreneurPostPage,
  TimelimitPostPage,
  SalesPostPage,
  ChallengesPage,
  CompetitorPage,
  LandingPage,
  MetricsPage,
  ModelPage,
  WireframesPage,
  PrototypePage,
  SalesPage,
  MarketingPage,
  ReferralsPage,
} from "./pages";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/details" element={<ApiDetailsPage />} />
        <Route path="/values" element={<ValuesPage />} />
        <Route path="/person" element={<PersonPage />} />
        <Route path="/functions" element={<FunctionsPage />} />
        <Route path="/market" element={<MarketPage />} />
        <Route path="/entrepreneur" element={<EntrepreneurPostPage />} />
        <Route path="/timelimit" element={<TimelimitPostPage />} />
        <Route path="/sales" element={<SalesPostPage />} />
        <Route path="/challenges" element={<ChallengesPage />} />
        <Route path="/competitor" element={<CompetitorPage />} />
        <Route path="/landing" element={<LandingPage />} />
        <Route path="/metrics" element={<MetricsPage />} />
        <Route path="/model" element={<ModelPage />} />
        <Route path="/wireframes" element={<WireframesPage />} />
        <Route path="/prototype" element={<PrototypePage />} />
        <Route path="/marketing" element={<MarketingPage />} />
        <Route path="/salesmaterials" element={<SalesPage />} />
        <Route path="/referrals" element={<ReferralsPage />} />
      </Routes>
    </div>
  );
}

export default App;
