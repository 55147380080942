import React from "react";
import { Details, Footer, Header } from "../components";

const product = {
  name: "Reservat",
  imageSrc:
    "https://images.pexels.com/photos/3782229/pexels-photo-3782229.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  version: { name: "1.0", date: "Junio 5, 2021", datetime: "2021-06-05" },
  description:
    "Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi.",
  goals: ["Endpoint 1", "Endpoint 1", "Endpoint 1"],
  requirements: ["Ninguno"],
  participants: ["Ninguno"],
  activities: ["Ninguno"],
  deliverables: ["Ninguno"],
};

const details = {
  content: `
  <h4>Información general</h4>
  
  <p>Aqui va toda la descripcion de los talleres, para que puedan entender de que trata y como los ayudaremos a lograr sus objetivos</p>

  <ul role="list">
  <li>Primer punto de los detalles</li>
  <li>Segundo punto de los detalles</li>
  </ul>
`,
};

const faqs = [
  {
    question: "Q1?",
    answer:
      "Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi.",
  },
  // More FAQs...
];

const ApiDetailsPage = () => {
  return (
    <div className="bg-gray-50">
      <Header />
      <Details product={product} faqs={faqs} details={details} />
      <Footer />
    </div>
  );
};

export default ApiDetailsPage;
