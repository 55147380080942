import React from "react";
import { Footer, Header, ReferralsPost } from "../components";

const ReferralsPage = () => {
  return (
    <div className="bg-gray-50">
      <Header />
      <ReferralsPost />
      <Footer />
    </div>
  );
};

export default ReferralsPage;
