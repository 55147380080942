import React from "react";
import { Footer, Header, SalesPost } from "../components";

const SalesPostPage = () => {
  return (
    <div className="bg-gray-50">
      <Header />
      <SalesPost />
      <Footer />
    </div>
  );
};

export default SalesPostPage;
