import React from "react";
import { Details, Footer, Header } from "../components";

const product = {
  name: "Secciones y Funciones",
  imageSrc:
    "https://images.pexels.com/photos/3782142/pexels-photo-3782142.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  version: { name: "1.0", date: "Agosto  1, 2022", datetime: "2022-08-01" },
  description:
    "Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi.",
  goals: [
    "Explorar opciones de secciones y funciones.",
    "Definir las secciones y funciones del alcance actual.",
  ],
  requirements: [
    "Taller: Métricas Clave",
    "Sugerencia: Taller de Personas Clave.",
  ],
  participants: ["De 2 a 8 personas"],
  activities: [
    "Recapitular a las Personas Clave, las metas del proyecto y las métricas clave.",
    "Individualmente aportar todas las secciones que se deseen (tomadas de sus listas).",
    "Grupalmente votar las secciones críticas para el alcance deseado.",
    "Grupalmente agregar las funciones viables que desean dentro de las secciones críticas.",
    "Crear la lista de secciones y funciones futuras (idealmente priorizadas por versiones)",
  ],
  deliverables: [
    "Lista de las secciones y sus funciones para el producto del alcance actual.",
    "Lista de secciones y funciones para versiones futuras.",
  ],
};

const details = {
  content: `
  <h2>Actividades Previas:</h2>
  <ul role="list">
  <li>Lista individual de secciones y funciones deseadas.</li>
  </ul>

  <h2>Formato:</h2>
  <ul role="list">
  <li>Síncrono</li>
  </ul>

  <h2>Tiempo:</h2>
  <ul role="list">
  <li>1 Hora</li>
  </ul>


  <p>Aqui va toda la descripcion de los talleres, para que puedan entender de que trata y como los ayudaremos a lograr sus objetivos</p>
`,
};

const faqs = [
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Get",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
];

const FunctionsPage = () => {
  return (
    <div className="bg-gray-50">
      <Header />
      <Details product={product} faqs={faqs} details={details} />
      <Footer />
    </div>
  );
};

export default FunctionsPage;
