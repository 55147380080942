import React from "react";
import { Details, Footer, Header } from "../components";

const product = {
  name: "Análisis de la competencia",
  imageSrc:
    "https://images.pexels.com/photos/7688336/pexels-photo-7688336.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  version: { name: "1.0", date: "Agosto  1, 2022", datetime: "2022-08-01" },
  description:
    "Para que el negocio tenga éxito, es importante saber qué competencia existe. Este taller lo guiará a través de cómo encontrar a sus competidores, cómo operan y cómo mantenerse competitivo.",
  goals: [
    "Explorar soluciones existentes al mismo reto (necesidad).",
    "Explorar 2 soluciones relacionadas en otras industrias.",
    "Hacer un comparativo (en una tabla) de las principales áreas entre todas las soluciones encontradas.",
    "Tener recursos gráficos (como referencia) de las soluciones comparadas.",
    "Crear una lista de recomendaciones (buenas y malas prácticas) tras las comparaciones.",
  ],
  requirements: ["Reto del Proyecto."],
  participants: ["De 2 a 5 personas."],
  activities: [
    "Como equipo crear un tablero donde poder agregar datos de lo que se vaya encontrando.",
    "Entre los integrantes del equipo hacer una búsqueda en internet y redes sociales con las palabras clave.",
    "Hacer una lista de todas las soluciones similares en la industria y en otras industrias.",
    "De las soluciones similares en la industria elegir las 3 más relevantes (puede cada integrante tener derecho a 2 votos).",
    "De las soluciones similares en otras industrias elegir las 2 más relevantes (puede cada integrante tener derecho a 1 voto).",
    "Repartirse entre el equipo las competencias-referencias para que cada quien vaya llenando la tabla.",
    "Para todas las soluciones similares (5) agregar a la tabla las áreas principales a comparar (fecha de creación, modelo de negocio, países disponible, servicios que ofrece, lista de funciones, links a redes sociales, cantidad de usuarios, montos de inversión, etc.)",
    "Llenar para cada solución sus datos de la tabla.",
    "Cada quien agregar a la lista de recomendaciones las buenas prácticas a implementar y las malas prácticas a evitar que aprendió de analizar su competencia-referencia.",
  ],
  deliverables: ["Tabla llena."],
};

const details = {
  content: `
  <h2>Información general</h2>
  
  <p>Aqui va toda la descripcion de los talleres, para que puedan entender de que trata y como los ayudaremos a lograr sus objetivos</p>

  <ul role="list">
  <li>Primer punto de los detalles</li>
  <li>Segundo punto de los detalles</li>
  </ul>
`,
};

const faqs = [
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Get",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
];

const CompetitorPage = () => {
  return (
    <div className="bg-gray-50">
      <Header />
      <Details product={product} faqs={faqs} details={details} />
      <Footer />
    </div>
  );
};

export default CompetitorPage;
