import React from "react";
import { Details, Footer, Header } from "../components";

const product = {
  name: "Reto del proyecto",
  imageSrc:
    "https://images.pexels.com/photos/2433291/pexels-photo-2433291.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  version: { name: "1.0", date: "Agosto  1, 2022", datetime: "2022-08-01" },
  description:
    "Encontrar los desafíos de su proyecto es un paso clave en el proceso de desarrollo del proyecto. Este taller lo ayudará a hacer las preguntas correctas, comprender su propia perspectiva y desarrollar un plan práctico para superar esos desafíos.",
  goals: [
    "Explorar los problemas y sus soluciones.",
    "Elegir las soluciones principales.",
    "Redactar los retos del proyecto.",
    "Definir los requisitos para los retos.",
  ],
  requirements: ["Persona Objetivo, Valores y Metas"],
  participants: ["De 2 a 5 personas."],
  activities: [
    "Recapitular las Metas y a las personas clave.",
    "Lluvia de ideas para descubrir los problemas.",
    "Explorar soluciones a los problemas.",
    "Votar cuáles son las soluciones principales.",
    "De las soluciones principales hacer los retos del proyecto.",
    "Descubrir los requisitos para cumplir los retos.",
  ],
  deliverables: [
    "Retos del proyecto (en pregunta).",
    "Requisitos para cumplir los retos.",
  ],
};

const details = {
  content: `
  <h2>Información general</h2>
  
  <p>Aqui va toda la descripcion de los talleres, para que puedan entender de que trata y como los ayudaremos a lograr sus objetivos</p>

  <ul role="list">
  <li>Primer punto de los detalles</li>
  <li>Segundo punto de los detalles</li>
  </ul>
`,
};

const faqs = [
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Get",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
];

const ChallengesPage = () => {
  return (
    <div className="bg-gray-50">
      <Header />
      <Details product={product} faqs={faqs} details={details} />
      <Footer />
    </div>
  );
};

export default ChallengesPage;
