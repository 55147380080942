import React from "react";
import { Details, Footer, Header } from "../components";

const product = {
  name: "Persona Objetivo",
  imageSrc:
    "https://images.pexels.com/photos/3782142/pexels-photo-3782142.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  version: { name: "1.0", date: "Agosto  1, 2022", datetime: "2022-08-01" },
  description:
    "Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt sint deserunt ut voluptate aute id deserunt nisi.",
  goals: [
    "Explorar las Personas Clave.",
    "Seleccionar a las más importantes.",
    "Llenar los datos de las 2 principales.",
  ],
  requirements: ["Taller: valores y metas"],
  participants: ["De 2 a 5 personas"],
  activities: [
    "Opciones de Personas Clave.",
    "Selección de máximo 2 Personas Clave.",
    "Exploración de las preguntas centrales.",
    "Lluvia de ideas cómo encontrar a estas Personas.",
  ],
  deliverables: ["Posters con las personas clave y los datos completados"],
};

const details = {
  content: `
  <h2>Actividades Previas:</h2>
  <ul role="list">
  <li>Lista individual de opciones de Personas Clave.</li>
  <li>Llenado básico de información de las Personas Clave</li>
  </ul>

  <h2>Formato:</h2>
  <ul role="list">
  <li>Síncrono</li>
  </ul>

  <h2>Tiempo:</h2>
  <ul role="list">
  <li>1 Hora</li>
  </ul>


  <p>Aqui va toda la descripcion de los talleres, para que puedan entender de que trata y como los ayudaremos a lograr sus objetivos</p>
`,
};

const faqs = [
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Get",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
];

const PersonPage = () => {
  return (
    <div className="bg-gray-50">
      <Header />
      <Details product={product} faqs={faqs} details={details} />
      <Footer />
    </div>
  );
};

export default PersonPage;
