import React from "react";
import { Details, Footer, Header } from "../components";

const product = {
  name: "Valores y Metas",
  imageSrc:
    "https://images.pexels.com/photos/3782144/pexels-photo-3782144.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  version: { name: "1.0", date: "Agosto  1, 2022", datetime: "2022-08-01" },
  description:
    "Si buscas descubrir y comprender tus valores, visión y propósito en la vida, este taller es perfecto para ti.",
  goals: [
    "Conocer los valores individuales.",
    "Definir los valores del equipo y mecanismos para practicarlos.",
    "Explorar las metas y deseos del proyecto.",
  ],
  requirements: ["Ninguno"],
  participants: ["De 2 a 8 personas."],
  activities: [
    "Lista de 5 valores individuales.",
    "Seleccionar los 3 principales y explicarlos.",
    "Compartir el valor principal junto con su explicación.",
    "Explorar las metas y deseos para el proyecto.",
    "Juntar los valores principales (serán los valores del equipo) y platicar prácticas o reglas para llevarlos a cabo.",
  ],
  deliverables: [
    "Poster con los valores principales y su reglas y prácticas.",
    "Poster con las metas y deseos del proyecto.",
  ],
};

const details = {
  content: `
  <h2>Información general</h2>
  
  <p>Aqui va toda la descripcion de los talleres, para que puedan entender de que trata y como los ayudaremos a lograr sus objetivos</p>

  <ul role="list">
  <li>Primer punto de los detalles</li>
  <li>Segundo punto de los detalles</li>
  </ul>
`,
};

const faqs = [
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Get",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
];

const SalesPage = () => {
  return (
    <div className="bg-gray-50">
      <Header />
      <Details product={product} faqs={faqs} details={details} />
      <Footer />
    </div>
  );
};

export default SalesPage;
