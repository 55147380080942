import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import React from "react";
export interface Props {
  product: {
    name: string;
    imageSrc: string;
    version: {
      name: string;
      date: string;
      datetime: string;
    };
    description: string;
    goals: string[];
    requirements: string[];
    participants: string[];
    activities: string[];
    deliverables: string[];
  };

  faqs: {
    question: string;
    answer: string;
  }[];

  details: {
    content: string;
  };
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const Details = (props: Props) => {
  const { product, details } = props;

  const [showModal1, setShowModal1] = React.useState(false);
  const [showModal2, setShowModal2] = React.useState(false);

  return (
    <div className="bg-white">
      <div className="mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        {/* Product */}
        <div className="lg:grid lg:grid-rows-1 lg:grid-cols-7 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
          {/* Product image */}
          <div className="lg:row-end-1 lg:col-span-4">
            <div className="aspect-w-4 aspect-h-3 rounded-lg bg-gray-100 overflow-hidden">
              <img
                src={product.imageSrc}
                alt=""
                className="object-center object-cover"
              />
            </div>
          </div>

          {/* Product details */}
          <div className="text-left max-w-2xl mx-auto mt-14 sm:mt-16 lg:max-w-none lg:mt-0 lg:row-end-2 lg:row-span-2 lg:col-span-3">
            <div className="flex flex-col-reverse">
              <div className="mt-4">
                <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                  {product.name}
                </h1>

                <h2 id="information-heading" className="sr-only">
                  Product information
                </h2>
                <p className="text-sm text-gray-500 mt-2">
                  Impartido por Rilow {product.version.name} (Actualizado{" "}
                  <time dateTime={product.version.datetime}>
                    {product.version.date}
                  </time>
                  )
                </p>
              </div>
            </div>

            <p className="text-gray-500 mt-6">{product.description}</p>

            <div className="border-t border-gray-200 mt-10 pt-10">
              <h3 className="text-sm font-medium text-gray-900">Objetivos</h3>
              <div className="mt-4 prose prose-sm text-gray-500">
                <ol>
                  {product.goals.map((goals) => (
                    <li key={goals}>{goals}</li>
                  ))}
                </ol>
              </div>
            </div>

            <div className="border-t border-gray-200 mt-10 pt-10">
              <h3 className="text-sm font-medium text-gray-900">
                Requerimientos
              </h3>
              <div className="mt-4 prose prose-sm text-gray-500">
                <ul>
                  {product.requirements.map((requirements) => (
                    <li key={requirements}>{requirements}</li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="border-t border-gray-200 mt-10 pt-10">
              <h3 className="text-sm font-medium text-gray-900">
                Participantes
              </h3>
              <div className="mt-4 prose prose-sm text-gray-500">
                <ul>
                  {product.participants.map((participants) => (
                    <li key={participants}>{participants}</li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="border-t border-gray-200 mt-10 pt-10">
              <h3 className="text-sm font-medium text-gray-900">Actividades</h3>
              <div className="mt-4 prose prose-sm text-gray-500">
                <ul>
                  {product.activities.map((activities) => (
                    <li key={activities}>{activities}</li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="border-t border-gray-200 mt-10 pt-10">
              <h3 className="text-sm font-medium text-gray-900">Entregables</h3>
              <div className="mt-4 prose prose-sm text-gray-500">
                <ul>
                  {product.deliverables.map((deliverables) => (
                    <li key={deliverables}>{deliverables}</li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="border-t border-gray-200 mt-10 pt-10">
              <h3 className="text-sm font-medium text-gray-900">Contratar</h3>
            </div>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
              <button
                className="w-full bg-blue-50 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-blue-700 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500"
                type="button"
                onClick={() => setShowModal1(true)}
              >
                Guiado
              </button>
              <button
                type="button"
                className="w-full bg-blue-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500"
                onClick={() => setShowModal2(true)}
              >
                Completo
              </button>
            </div>

            {/* <div className="border-t border-gray-200 mt-10 pt-10">
              <h3 className="text-sm font-medium text-gray-900">Share</h3>
              <ul className="flex items-center space-x-6 mt-4">
                <li>
                  <nav className="flex items-center justify-center w-6 h-6 text-gray-400 hover:text-gray-500">
                    <Link to="/">
                      <span className="sr-only">Share on Facebook</span>
                      <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Link>
                  </nav>
                </li>
                <li>
                  <nav className="flex items-center justify-center w-6 h-6 text-gray-400 hover:text-gray-500">
                    <Link to="/">
                      {" "}
                      <span className="sr-only">Share on Instagram</span>
                      <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Link>
                  </nav>
                </li>
                <li>
                  <nav className="flex items-center justify-center w-6 h-6 text-gray-400 hover:text-gray-500">
                    <Link to="/">
                      <span className="sr-only">Share on Twitter</span>
                      <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                      </svg>
                    </Link>
                  </nav>
                </li>
              </ul>
            </div> */}
          </div>

          <div className="w-full max-w-2xl mx-auto mt-16 lg:max-w-none lg:mt-0 lg:col-span-4">
            <Tab.Group as="div">
              <div className="border-b border-gray-200">
                <Tab.List className="-mb-px flex space-x-8">
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        selected
                          ? "border-blue-600 text-blue-600"
                          : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                        "whitespace-nowrap py-6 border-b-2 font-medium text-sm"
                      )
                    }
                  >
                    Detalles
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        selected
                          ? "border-blue-600 text-blue-600"
                          : "border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300",
                        "whitespace-nowrap py-6 border-b-2 font-medium text-sm"
                      )
                    }
                  ></Tab>
                </Tab.List>
              </div>
              <Tab.Panels as={Fragment}>
                <Tab.Panel className="text-sm text-gray-500">
                  <h3 className="sr-only">Details</h3>
                  <div
                    className="prose prose-sm text-left max-w-none text-gray-500 mt-6"
                    dangerouslySetInnerHTML={{ __html: details.content }}
                  />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </div>

      {showModal1 ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-8 max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-8 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                    Hazlo tu mismo
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal2(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto text-left">
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                    <h3 className="text-xl font-medium">Contenido:</h3>
                    <h3 className="text-xl font-medium">Ejemplos:</h3>
                    <h3 className="text-xl font-medium">
                      Nivel de Dificultad:
                    </h3>
                    <h3 className="text-xl font-medium">
                      Tiempo para aprender:
                    </h3>
                    <ul>
                      <li>Reviewer $</li>
                      <li>Coach $</li>
                      <li>Test $</li>
                    </ul>
                  </p>
                </div>
                {/*footer*/}
                <div className="m-8 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
                  <button
                    className="w-full bg-blue-50 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-blue-700 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500"
                    type="button"
                    onClick={() => setShowModal1(false)}
                  >
                    Cerrar
                  </button>
                  <button
                    className="w-full bg-blue-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500"
                    type="button"
                    onClick={() => setShowModal1(false)}
                  >
                    Contratar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {showModal2 ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-8 max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-8 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                    Contrata a Rilow
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal2(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto text-left">
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                    <h3 className="text-xl font-medium">Contenido:</h3>
                    <h3 className="text-xl font-medium">Ejemplos:</h3>
                    <h3 className="text-xl font-medium">
                      Nivel de Dificultad:
                    </h3>
                    <h3 className="text-xl font-medium">
                      Tiempo para aprender:
                    </h3>
                    <ul>
                      <li>Reviewer $</li>
                      <li>Coach $</li>
                      <li>Test $</li>
                    </ul>
                  </p>
                </div>
                {/*footer*/}
                <div className="m-8 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
                  <button
                    className="w-full bg-blue-50 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-blue-700 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500"
                    type="button"
                    onClick={() => setShowModal2(false)}
                  >
                    Cerrar
                  </button>
                  <button
                    className="w-full bg-blue-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500"
                    type="button"
                    onClick={() => setShowModal2(false)}
                  >
                    Contratar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
};

export default Details;
