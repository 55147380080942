import React from "react";
import { Details, Footer, Header } from "../components";

const product = {
  name: "Métricas clave",
  imageSrc:
    "https://images.pexels.com/photos/7948054/pexels-photo-7948054.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  version: { name: "1.0", date: "Agosto  1, 2022", datetime: "2022-08-01" },
  description:
    "Podemos ayudarlo a encontrar sus métricas clave para comunicar el valor de su producto a sus clientes. Este taller lo ayudará a determinar qué métricas son las más importantes para su negocio y cómo medirlas de manera efectiva.",
  goals: [
    "Un taller para explorar y decidir qué requisitos debe tener el diseño para ser exitoso. También explorar y decidir qué datos son los más valiosos para el usuario. ",
  ],
  requirements: ["Secciones y Funciones"],
  participants: ["De 2 a 5 personas."],
  activities: [
    "Recapitular las secciones y funciones del alcance actual.",
    "Lluvia de ideas grupal para crear una lista de requisitos que debe cumplir el diseño.",
    "Priorización de los requisitos de diseño importantes y no importantes.",
    "Lluvia de ideas grupal para crear la lista de datos más valiosos para el usuario.",
    "Selección de los datos más valiosos que se puedan mostrar en esta etapa (alcance).",
  ],
  deliverables: [
    "Lista priorizada de requisitos de diseño.",
    "Lista priorizada de números clave.",
  ],
};

const details = {
  content: `
  <h2>Información general</h2>
  
  <p>Aqui va toda la descripcion de los talleres, para que puedan entender de que trata y como los ayudaremos a lograr sus objetivos</p>

  <ul role="list">
  <li>Primer punto de los detalles</li>
  <li>Segundo punto de los detalles</li>
  </ul>
`,
};

const faqs = [
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Get",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
];

const MetricsPage = () => {
  return (
    <div className="bg-gray-50">
      <Header />
      <Details product={product} faqs={faqs} details={details} />
      <Footer />
    </div>
  );
};

export default MetricsPage;
