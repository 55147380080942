import React from "react";

const posts = [
  {
    title: "Emprender exige darlo todo",
    href: "/entrepreneur",
    category: { name: "Rilow", href: "/category" },
    description:
      "Tus amigos ya se empiezan a casar, viven solos en alguna zona cool. Salen de viaje y a bares y a restaurantes. Hablan de los proyectos de la empresa y de nuevas ofertas de trabajo. La estabilidad los hace brillar.",
    date: "Ago 17, 2022",
    datetime: "2022-08-17",
    imageUrl:
      "https://images.pexels.com/photos/449609/pexels-photo-449609.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    readingTime: "3 min",
    author: {
      name: "Dadelo Solís",
      href: "/Dadelo",
      imageUrl:
        "https://user-images.githubusercontent.com/8493442/185163233-8c0fe9a0-fbd4-4952-9219-04d324ea29b5.jpg",
    },
  },
  {
    title: "El Valor del Límite de Tiempo",
    href: "/timelimit",
    category: { name: "Rilow", href: "/category" },
    description:
      "Ningún proyecto escapa a esta situación. A todo proyecto siempre le podremos agregar, cambiar y quitar “cosas”. Es la naturaleza humana de siempre buscar más, de siempre soñar con más y mejor. Pero para un emprendimiento, las obras maestras generalmente no convienen.",
    date: "Ago 16, 2022",
    datetime: "2022-08-16",
    imageUrl:
      "https://images.pexels.com/photos/12829900/pexels-photo-12829900.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    readingTime: "3 min",
    author: {
      name: "Dadelo Solís",
      href: "/Dadelo",
      imageUrl:
        "https://user-images.githubusercontent.com/8493442/185163233-8c0fe9a0-fbd4-4952-9219-04d324ea29b5.jpg",
    },
  },
  {
    title: "¿Construir o Vender?",
    href: "/sales",
    category: { name: "Rilow", href: "/category" },
    description:
      "Por alguna razón humana, al empezar a crear un proyecto —que ante nuestros ojos es increíble y cambiará al mundo— creemos que al tenerlo listo “mágicamente” la gente se va a enterar y “automáticamente” la gente va a empezar a gozar de sus beneficios. Creamos que al tener el producto correcto, el éxito llegará solito.",
    date: "Ago 15, 2022",
    datetime: "2022-08-15",
    imageUrl:
      "https://images.pexels.com/photos/5920658/pexels-photo-5920658.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    readingTime: "3 min",
    author: {
      name: "Dadelo Solís",
      href: "/Dadelo",
      imageUrl:
        "https://user-images.githubusercontent.com/8493442/185163233-8c0fe9a0-fbd4-4952-9219-04d324ea29b5.jpg",
    },
  },
];

const BlogPost = () => {
  return (
    <div className="relative bg-gray-100 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-bold text-gray-900 sm:text-4xl sm:tracking-tight">
            No Soy Un Blog
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            Intenta leer un artículo a la semana
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {posts.map((post) => (
            <div
              key={post.title}
              className="flex flex-col rounded-lg shadow-lg overflow-hidden"
            >
              <div className="flex-shrink-0">
                <img
                  className="h-48 w-full object-cover"
                  src={post.imageUrl}
                  alt=""
                />
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-blue-600">
                    <a href={post.category.href} className="hover:underline">
                      {post.category.name}
                    </a>
                  </p>
                  <a href={post.href} className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">
                      {post.title}
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                      {post.description}
                    </p>
                  </a>
                </div>
                <div className="mt-6 flex items-start">
                  <div className="flex-shrink-0">
                    <a href={post.author.href}>
                      <span className="sr-only ">{post.author.name}</span>
                      <img
                        className="h-10 w-10 rounded-full"
                        src={post.author.imageUrl}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      <a href={post.author.href} className="hover:underline">
                        {post.author.name}
                      </a>
                    </p>
                    <div className="flex space-x-1 text-sm text-gray-500">
                      <time dateTime={post.datetime}>{post.date}</time>
                      <span aria-hidden="true">&middot;</span>
                      <span>{post.readingTime} Lectura</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
