import React from "react";
import { Details, Footer, Header } from "../components";

const product = {
  name: "Landing Page",
  imageSrc:
    "https://media.istockphoto.com/photos/website-creative-plan-development-with-drawn-sketch-in-the-design-picture-id1221435509?k=20&m=1221435509&s=612x612&w=0&h=JWs9wwlkwP6PYvDE4OuSYCSI9a3UYK8xwaeK-gtNiiE=",
  version: { name: "1.0", date: "Agosto  1, 2022", datetime: "2022-08-01" },
  description:
    "Las Landing Pages son la primera oportunidad para vender tu producto y la última oportunidad que tienes para causar una buena impresión en el visitante. Deben diseñarse para convertir a los visitantes en clientes centrándose en los beneficios, transmitiendo confianza y credibilidad, demostrando lo fácil que es usar su producto y, cuando sea posible, ofreciendo algún tipo de incentivo u oferta por tiempo limitado.",
  goals: [
    "Crear la página de referencia del proyecto, que será la imagen de cara hacia el mundo.",
  ],
  requirements: ["Etapa de Descubrimiento."],
  participants: ["De 2 a 5 personas."],
  activities: [
    "Recapitular la estrategia de comercialización (para entender a quiénes estamos enfocados).",
    "Tener el contenido para las secciones.",
  ],
  deliverables: [
    "Idealmente: landing page funcional",
    "En caso de no poder tener la landing funcional: Diseño de la landing page.",
  ],
};

const details = {
  content: `
  <h2>Información general</h2>
  
  <p>Aqui va toda la descripcion de los talleres, para que puedan entender de que trata y como los ayudaremos a lograr sus objetivos</p>

  <ul role="list">
  <li>Primer punto de los detalles</li>
  <li>Segundo punto de los detalles</li>
  </ul>
`,
};

const faqs = [
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Get",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
];

const LandingPage = () => {
  return (
    <div className="bg-gray-50">
      <Header />
      <Details product={product} faqs={faqs} details={details} />
      <Footer />
    </div>
  );
};

export default LandingPage;
