import React from "react";
import { Details, Footer, Header } from "../components";

const product = {
  name: "Diseño versión 2",
  imageSrc:
    "https://st4.depositphotos.com/10048732/23723/i/450/depositphotos_237231274-stock-photo-web-designer-designer-design-user.jpg",
  version: { name: "1.0", date: "Agosto  1, 2022", datetime: "2022-08-01" },
  description:
    "Llegaste al mapa de tu idea y ahora te preguntas cómo ponerlo todo junto. Este taller es para ti. En esta sesión práctica, lo guiaremos a través de cómo crear un prototipo completamente funcional que valide sus ideas.",
  goals: [
    "Transformar la primera versión gráfica hacia una más real y terminada para comunicar el proyecto cercano a su versión final.",
  ],
  requirements: ["Diseño versión 1, sugerido Retroalimentación."],
  participants: ["De 1 a 3 personas."],
  activities: [
    "Definir la tipografía y los estilos (para títulos, subtítulos, textos normales, etc.).",
    "Definir paleta de colores (unos 4 colores, pueden usar coolors.co)",
    "Empezar a usar íconos e imágenes “finales” (que se vean reales y sean lo más cercano a lo que se desea).",
    "Pasar a V2 las pantallas que se tienen en la V1.",
    "Crear el prototipo funcional de la versión 2",
  ],
  deliverables: [
    "Documento con las tipografías y la paleta de colores.",
    "Pantallas individuales del Diseño V2.",
    "Enlace al Prototipo Versión 2.",
  ],
};

const details = {
  content: `
  <h2>Información general</h2>
  
  <p>Aqui va toda la descripcion de los talleres, para que puedan entender de que trata y como los ayudaremos a lograr sus objetivos</p>

  <ul role="list">
  <li>Primer punto de los detalles</li>
  <li>Segundo punto de los detalles</li>
  </ul>
`,
};

const faqs = [
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Get",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
];

const PrototypePage = () => {
  return (
    <div className="bg-gray-50">
      <Header />
      <Details product={product} faqs={faqs} details={details} />
      <Footer />
    </div>
  );
};

export default PrototypePage;
