import React from "react";
import { Details, Footer, Header } from "../components";

const product = {
  name: "Diseño versión 1",
  imageSrc:
    "https://images.pexels.com/photos/196644/pexels-photo-196644.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  version: { name: "1.0", date: "Agosto  1, 2022", datetime: "2022-08-01" },
  description:
    "Este taller lo ayudará a encontrar la estructura de su producto. Este es un flujo de trabajo paso a paso que lleva el viaje del usuario a través de su producto y le brindará un mapa único desde la visualización de conceptos básicos hasta esquemas presentables.",
  goals: [
    "Dos caminos para llegar a tener las pantallas iniciales de un proyecto. El objetivo es crear la primera versión gráfica.",
  ],
  requirements: [
    "Secciones y funciones, se sugiere tener el Mapa del Recorrido si hay gran cantidad de secciones y funciones. ",
  ],
  participants: ["De 1 a 5 personas."],
  activities: [
    "Se les facilita, a las personas que realizarán el diseño, la lista de secciones y funciones priorizada (es decir con la división entre las secciones y funciones de este alcance y las que se dejarán para versiones futuras).",
    "Comunicar los entregables de retro del proyecto y métricas clave para que los diseños lo tomen como base y cumplan lo mejor posible con ello. ",
    "El diseño debe integrar todas las secciones y funciones.",
    "El diseño debe hacerse para móviles (opcional hacerlo también para desktop).",
    "En caso de bloqueos o dudas, pueden consultar la tabla y referencias del entregable de Análisis de la Competencia.",
    "Se comunica un día y una hora límite para tener el diseño versión 1 (para referencia: toma un par de horas a lo mucho).",
    "Se dan las instrucciones de dónde y cómo enviar (documentar) el entregable. (Subir las pantallas en X documento, avisar al grupo, etc.)",
  ],
  deliverables: ["Wireframes del proyecto"],
};

const details = {
  content: `
  <h2>Información general</h2>
  
  <p>Aqui va toda la descripcion de los talleres, para que puedan entender de que trata y como los ayudaremos a lograr sus objetivos</p>

  <ul role="list">
  <li>Primer punto de los detalles</li>
  <li>Segundo punto de los detalles</li>
  </ul>
`,
};

const faqs = [
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Get",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
];

const WireframesPage = () => {
  return (
    <div className="bg-gray-50">
      <Header />
      <Details product={product} faqs={faqs} details={details} />
      <Footer />
    </div>
  );
};

export default WireframesPage;
