import React from "react";
import {
  BlogPost,
  Footer,
  Header,
  Hero,
  Pricing,
  WorkShops,
} from "../components";

const HomePage = () => {
  return (
    <div className=" bg-white ">
      <Header />
      <Hero />
      <WorkShops />
      <Pricing />
      <BlogPost />
      <Footer />
    </div>
  );
};

export default HomePage;
