import React from "react";
import { Footer, Header, EntrepreneurPost } from "../components";

const EntrepreneurPostPage = () => {
  return (
    <div className="bg-gray-50">
      <Header />
      <EntrepreneurPost />
      <Footer />
    </div>
  );
};

export default EntrepreneurPostPage;
