import React from "react";
import { Details, Footer, Header } from "../components";

const product = {
  name: "Modelo de negocio",
  imageSrc:
    "https://images.pexels.com/photos/3184430/pexels-photo-3184430.jpeg",
  version: { name: "1.0", date: "Agosto  1, 2022", datetime: "2022-08-01" },
  description:
    "Empiece hoy. Trabaje con una empresa que pueda identificar su modelo de negocio y cómo crecer.",
  goals: [
    "Un taller para explorar y decidir cuál es el modelo de negocio conveniente para este alcance, además de tener opciones para modificar el modelo en alcances futuros.",
  ],
  requirements: ["Análisis de la competencia."],
  participants: ["De 2 a 5 personas."],
  activities: [
    "Recapitular la tabla de la competencia, especialmente en cuanto a sus fuentes de ingreso.",
    "Breve exploración alrededor de la pregunta ¿A quién y en dónde le aportamos valor?",
    "Lluvia de ideas grupal para crear una lista de todas las fuentes de ingreso que podrían aplicar.",
    "Votación de las fuentes de ingreso viables y no viables para este alcance.",
    "*Tener en mente las respuestas a la pregunta de a quién y dónde se aporta valor.",
    "Intentar colocar estimados de dinero (basados en usuarios, peticiones, etc.)",
    "*La intención es crear una pequeña e inicial proyección de ingresos.",
    "*Crear el entregable, donde se explica claramente cuál será el modelo de negocio actual.",
  ],
  deliverables: ["Redaccion de documento con modelo de negocio"],
};

const details = {
  content: `
  <h2>Información general</h2>
  
  <p>Aqui va toda la descripcion de los talleres, para que puedan entender de que trata y como los ayudaremos a lograr sus objetivos</p>

  <ul role="list">
  <li>Primer punto de los detalles</li>
  <li>Segundo punto de los detalles</li>
  </ul>
`,
};

const faqs = [
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Get",
    answer: "Descripcion del Endpoint",
  },
  {
    question: "Post",
    answer: "Descripcion del Endpoint",
  },
];

const ModelPage = () => {
  return (
    <div className="bg-gray-50">
      <Header />
      <Details product={product} faqs={faqs} details={details} />
      <Footer />
    </div>
  );
};

export default ModelPage;
