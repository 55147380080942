import React from "react";

const Details = () => {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-lg text-center text-blue-600 font-semibold">
              Rilow
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl sm:tracking-tight">
              ¿Construir o Vender?
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Por alguna razón humana, al empezar a crear un proyecto —que ante
            nuestros ojos es increíble y cambiará al mundo— creemos que al
            tenerlo listo “mágicamente” la gente se va a enterar y
            “automáticamente” la gente va a empezar a gozar de sus beneficios.
            Creamos que al tener el producto correcto, el éxito llegará solito.
          </p>
        </div>
        <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
          <p>
            Haber entendido esta profunda y sencilla ilusión nos tomó 4 años. La
            verdad es esta: no importa lo que construyas (incluso si creas la
            cura del cáncer), si no tienes la capacidad de “venderlo”, tu
            proyecto tendrá el mismo impacto que un árbol que cae sin testigos
            en la mitad del bosque.
          </p>
          <figure>
            <img
              className="w-full rounded-lg"
              src="https://images.pexels.com/photos/5920658/pexels-photo-5920658.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt=""
              width={1310}
              height={873}
            />
            <figcaption>FigCaption.</figcaption>
          </figure>
          <p>
            Dicho de otra forma. Ten en cuenta, desde el inicio, que la misma
            cantidad de tiempo y esfuerzo que le dedicarás a la construcción de
            tu proyecto se la tendrás que dedicar —obligadamente— a la
            comunicación; la venta y comercialización.
          </p>
          <p>
            Esperamos que con esta lección entiendas que al terminar la etapa de
            la creación vas apenas a la mitad del camino... continua la etapa de
            vender.
          </p>
          <p>
            Por si no quedó claro: es igual o más importante tu dedicación a
            vender que a crear el proyecto.
          </p>
          <p>Repite esta frase como mantra:</p>
          <blockquote>
            <p>
              El éxito depende de crear y de vender. El éxito depende de vender
              y de crear.
            </p>
          </blockquote>
          <p>
            Nos harás sentir orgullosos si este mensaje te ayuda a no invertir
            años de tu vida en un proyecto que al final te desilusione.
          </p>
          <p>
            Es importante tener en mente el esfuerzo, y la misma sangre, que
            tomará la etapa de las ventas. Que no te asuste, es y será tan o más
            divertida que la parte de la creación. Platicarás con personas
            fascinantes con diferentes puntos de vista. Aprenderás de la
            realidad y sus infinitos detalles. Conocerás lugares a los que nunca
            imaginaste ir. Serás testigo de habilidades tuyas que jamás creerías
            tener.
          </p>
          <p>
            Una vez que nades confiado en estas dos aguas, llegarás a entender
            que ambas aguas fluyen dentro del mismo mar. Llegarás a observar que
            construir y vender son dos acciones distintas que suceden al mismo
            tiempo y de forma continua.
          </p>
          <p>Hasta entonces...</p>
        </div>
      </div>
    </div>
  );
};

export default Details;
