import React from "react";

const Details = () => {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div
          className="relative h-full text-lg max-w-prose mx-auto"
          aria-hidden="true"
        >
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
            />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
            />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-lg text-center text-blue-600 font-semibold">
              Rilow
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl sm:tracking-tight">
              Emprender exige darlo todo
            </span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Tus amigos ya se empiezan a casar, viven solos en alguna zona cool.
            Salen de viaje y a bares y a restaurantes. Hablan de los proyectos
            de la empresa y de nuevas ofertas de trabajo. La estabilidad los
            hace brillar.
          </p>
        </div>
        <div className="mt-6 prose prose-blue prose-lg text-gray-500 mx-auto">
          <p>
            Tú. Vives en casa de tus papás, sales de viaje sólo con la familia o
            cuando te invitan los suegros. Ese incómodo tema de ¿a qué te
            dedicas? te pone frío. Vives, casi, al día; todo tu dinero se ha ido
            y se sigue yendo a tu proyecto. Cuando sales a algún lugar, si es
            que recuerdas la última vez, vas a planes gratis o semi gratis. Pero
            trabajas en El Proyecto que cambiará al mundo.
          </p>
          <figure>
            <img
              className="w-full rounded-lg"
              src="https://images.pexels.com/photos/449609/pexels-photo-449609.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              alt=""
              width={1310}
              height={873}
            />
            <figcaption>FigCaption.</figcaption>
          </figure>
          <p>
            Emprender exige darlo todo. Sospecho que la razón es más psicológica
            que práctica..
          </p>
          <p>
            Hay algo profundamente misterioso en el emprendedor. Visto con calma
            y desde lejos, parecería que su principal proyecto es él mismo; su
            superación personal.
          </p>
          <p>
            Miedos, inseguridades, ansiedades, fantasías, traumas, dinámicas
            familiares... Al observar de cerca y sin juicio a un emprendedor,
            llama la atención -al menos para mí- una simple verdad: su
            emprendimiento es el medio (pretexto) donde se enfrenta a los retos
            y situaciones que él mismo quiere superar. El éxito de su proyecto
            es la conquista de sí mismo.
          </p>
          <p>
            Existe en la sociedad, al menos en estos años, una cierta mística
            alrededor de los emprendedores. Los vemos como una aspiración, como
            el ideal a escaparse del sistema. Es su propio jefe. Creó su propia
            empresa. Se dedica a algo fascinante. Tiene la vida a la que muchos
            aspiramos…
          </p>
          <p>
            Emprender exige darlo todo; ir en contra de lo que se cree posible,
            hacer inumerables sacrificios... y más difícil que todo: navegar en
            soledad por las crueles tormentas de tu propia mente.
          </p>
          <p>
            Incomprendido. Sin dinero. Sin validación material. En contra del
            buen consejo de familia y amigos. Despertar diario ante la máxima
            incertidumbre. Asociar tu identidad a tu proyecto. Miedo al éxito,
            al fracaso, al ridículo…
          </p>
          <p>
            Tantas áreas por hacer: crear, vender, liderar, comunicar, planear,
            decidir... tantas áreas por hacer en el mismo día. ¿Estás de buen
            humor? ¿Estás de mal humor? ¿Te sientes triste, deprimido,
            desilusionado? ¿Estás maniaco, motivado, optimista? Cada día haces
            de todo, te sientes arriba y abajo. Y el día siguiente será más
            retador. Emprender exige darlo todo.
          </p>
          <p>
            Quizás lo que inconscientemente admiramos y nos asusta de un
            emprendedor es la valentía de haberse enfrentado a sí mismo... y
            haberse conquistado.
          </p>
          <p>
            Dicen que para eso vinimos al mundo: para trabajar en desarrollarnos
            a nosotros mismos. Y en cierto grado todos lo hacemos, la diferencia
            está en el nivel a que lo llevas ¿al máximo? Por eso las
            emprendedoras exitosas son tan escasas. Se enfrentaron a sí
            mismas... y vencieron.
          </p>
          <p>¿Quién dijo que tiene que ser fácil?</p>
          <p>
            Platícanos una historia tuya de algún reto personal gigante al que
            te hayas o estés enfrentado.
          </p>

          {/* <strong>Dignissim</strong>{" "}
        <a href="#">Mattis mauris semper</a> 
        Podemos usar ligas y strongs */}
          {/* <ul role="list">
        <li>Quis elit egestas venenatis mattis dignissim.</li>
        <li>
          Cras cras lobortis vitae vivamus ultricies facilisis tempus.
        </li>
        <li>Orci in sit morbi dignissim metus diam arcu pretium.</li>
      </ul> 
      Podemos usar listas */}
          {/* <h2>Subtitle</h2> 
      Podemos agregar subtitulos*/}
          {/* <blockquote>
        <p>
          Emprender exige darlo todo; ir en contra de lo que se cree
          posible, hacer inumerables sacrificios... y más difícil que todo:
          navegar en soledad por las crueles tormentas de tu propia mente.
        </p>
      </blockquote> 
      Podemos hacer Quotes*/}
        </div>
      </div>
    </div>
  );
};

export default Details;
